import * as types from './actionType'

export const initialState = {
  rdPinFileList: [],
  transactionTrackerList: [],
  hardwareInventoryList: [],
}

export default function procurementReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_RD_PIN_FILE: {
      const newState = {
        ...state,
        rdPinFileList: action.payload,
      }
      return newState
    }
    case types.GET_TRANSACTION_TRACKER: {
      const newState = {
        ...state,
        transactionTrackerList: action.payload,
      }
      return newState
    }
    default: {
      return state
    }
    case types.POST_HW_LOG: {
      const newState = {
        ...state,
        eventsMessage: action.payload,
      }
      return newState
    }
    case types.GET_HW_LOG_OPTIONS: {
      const newState = {
        ...state,
        hwLogOptions: action.payload,
      }
      return newState
    }
    case types.CLEAR_MESSAGE: {
      const newState = {
        ...state,
        eventsMessage: action.payload,
      }
      return newState
    }
    case types.GET_HARDWARE_INVENTORY: {
      const newState = {
        ...state,
        hardwareInventoryList: action.payload,
      }
      return newState
    }
    case types.UPLOAD_INVENTORY_FILE: {
      // New case for handling inventory file upload
      const newState = {
        ...state,
        inventoryUploadResult: action.payload, // Update the state with the upload result
      }
      return newState
    }
  }
}
