import React from 'react'
import { connect } from 'react-redux'
import { Grid, Typography, CardContent, TextField, Button } from '@mui/material'
import { uploadInventoryFile } from '../../../store/Procurement/actionCreator'
import Firefly from 'firefly-sdk-ts'
import apiConfig from '../../../config/apiConfig'

export class inventoryCheckerTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      requestor: '',
      shipment: '',
      file: null,
    }
  }

  async componentDidMount() {
    const { userInfo } = this.props
    // await this.props.uploadInventoryFile
    const internalappEventManager = Firefly.getEventManager(
      apiConfig.fireflyConfig,
    )
    internalappEventManager.addEvent({
      application: { name: 'ziptie' },
      guest: { eventType: 'pageload' },
      event: { type: 'pageload', name: 'Inventory Checker' },
      user: { id: userInfo.email },
    })
  }

  handleInputChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleFileChange = (e) => {
    this.setState({ file: e.target.files[0] })
  }

  handleSubmit = () => {
    const { a_or_b, shipment, file } = this.state
    const { userInfo, dispatch } = this.props

    if (!file) {
      alert('Please upload a file.')
      return
    }

    const formData = new FormData()
    formData.append('requestor', userInfo.email)
    formData.append('shipment', shipment)
    formData.append('a_or_b', a_or_b)
    formData.append('rawFile', file)

    // Dispatch the action creator
    dispatch(uploadInventoryFile(formData))
      .then((response) => {
        // Update the state with the response
        this.setState({ responseMessage: response })
      })
      .catch((error) => {
        console.error('Error uploading file:', error)

        // Handle error response and update the state
        const errorMessage =
          error.response && error.response.data
            ? error.response.data
            : 'An unexpected error occurred.'
        this.setState({ responseMessage: errorMessage })
      })
  }

  render() {
    const { a_or_b, shipment, responseMessage } = this.state

    return (
      <CardContent
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Typography variant="h5" gutterBottom>
          Upload Kitting Document File
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="A or B (Leave blank if you want to check the whole file)"
              name="a_or_b"
              value={a_or_b}
              onChange={this.handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Shipment Number (Leave blank if you want to check the whole file)"
              name="shipment"
              value={shipment}
              onChange={this.handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <input
              type="file"
              accept=".xlsx"
              onChange={this.handleFileChange}
              style={{ marginTop: '16px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
            >
              Submit
            </Button>
          </Grid>
          {responseMessage && (
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary">
                {typeof responseMessage === 'string'
                  ? responseMessage
                  : JSON.stringify(responseMessage)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
})

export default connect(
  (state) => ({
    userInfo: state.auth.userInfo,
  }),
  mapDispatchToProps,
)(inventoryCheckerTab)
